h1 {
    text-align: center;
    color: white
}


.hero {
    background-color: black;
    /* background-color: rgb(48, 52, 63); */

    color: black;
    container-name: sizing;
    container-type: inline-size;
    display: block;

    .logo {
        font-size: 16.0vw;
        color: white;
        font-family: "Unica One";
    }

    .sublogo {
        display: block;
        width: 75%;
        font-size: 1.5em;
        color: white;
        text-align: center;
        /* font-family: "Roboto"; */
        font-family: "Spline Sans Mono";

        /* transform: translate(-22%, 0%); */
        /* transform: translate(1%, 0%); */

    }

    .staytunedbutton {
        display: block;
        width: 50%;
        font-size: 2.5em;
        color: white;
        transform: translate(-22%, 0%);
    }

    @container sizing (min-width: 1200px) {
        .logo {
            font-size: 18.0vw;
        }

        .sublogo {
            /* transform: translate(-31%, 0%); */
            /* transform: translate(-5%, 0%); */

        }

        .staytunedbutton {
            transform: translate(-31%, 0%);

        }
    }

    @container sizing (max-width: 800px) {
        .sublogo {
            width: 75%;
            /* transform: translate(0%, 0%); */
        }
    }
}

.hero2 {
    font-family: "Unica One";
    color: white;
    font-size: 6.0vw;
    /* container-name: sizing1;
    container-type: inline-size;
    display: block;  
    font-size: 18.0vw; */

}


/* @container sizing1 (min-width: 1200px) {
    .hero2 {
        font-size: 1.0vw;
    }
    
} */



.calltoaction {
    background-color: white;
    color: black;
    container-name: sizing;
    container-type: inline-size;
    display: block;

    .title {
        font-size: 5em;
        color: black;
        font-family: "Unica One";

        text-align: center;
    }

    .text {
        font-size: 2em;
        color: black;
        /* font-family: "Unica One"; */
        font-family: "Spline Sans Mono";

        text-align: center;
        width: 50%;
    }

    .input {
        margin-top: 20px;
        margin-bottom: 20px;

        font-size: 12.5vw;
        font-family: "Unica One";
        text-align: left;
        width: 40%;
    }

    .homebutton {
        display: block;
        /* width: 50%; */
        font-size: 2.5em;
        color: white;
        /* background-color: black; */
        /* background-color: black; */
        text-align: center;
        /* font-family: "Roboto"; */
        /* transform: translate(-22%, 0%); */
    }
}

.contact {
    background-color: black;
    color: white;

    .title {
        font-size: 5em;
        font-family: "Unica One";
        text-align: center;
        padding-bottom: 50px;

    }

    .text {
        padding-left: 10px;
        padding-right: 0px;
        font-size: 20px;
        /* font-family: "Unica One"; */
        font-family: "Spline Sans Mono";
        /* text-align: left; */
        /* width: 50%; */
    }

    .link {
        font-size: 20px;
        /* font-family: "Unica One"; */
        font-family: "Spline Sans Mono";
        padding-bottom: 50px;
        /* text-align: left; */
        /* width: 50%; */
    }
}

.footer {
    background-color: black;
    color: white;
    padding-top: 50px;
    text-align: center;

}